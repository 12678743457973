import React, { useContext, useEffect } from 'react';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import { Grid, Row, Col } from 'react-flexbox-grid';

import SourceEmitter from 'libs/emitter';
import { appContext } from 'providers/appProvider';
import { Layout, Seo, FaqsSet } from 'components';
import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';
import FaqsDataPatient from 'shared/FaqsDataPatient';

import './faqs.scss';

const faqsPage = () => {
  let Subscription = null;
  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.patient;
  const metaData = appConfigs?.metaData?.patient;

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const renderPage = () => {
    return (
      <div id="nascobal-patient-resource">
        <ContentBlock>
          <Grid
            fluid
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
            }}
          >
            <Row>
              <Col xs={12}>
                <ColorBlock blue>
                  <h2>Frequently asked questions about PATIENT</h2>
                  <h1 className="orange">
                    NUTRITION DIRECT
                    <span className="brand-trade-mark">&trade;</span> WITH
                    BARIACTIV<sup>&reg;</sup> SUPPLEMENTS
                  </h1>
                  <p>
                    It is not unusual to have questions. Here are some of the
                    most common ones and their answers.
                  </p>
                </ColorBlock>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FaqsSet
                  faqData={FaqsDataPatient.nutritionDirectSupplementData}
                  className=""
                />
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="patient-faqs">
      <Seo {...metaData} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default faqsPage;
